

















import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { defineComponent } from '@nuxtjs/composition-api'
import CardDefault from '@/components/Cards/CardDefault.vue'
@Component
class CardDefaultList extends Vue {
  @Prop() readonly list: ReadonlyArray<any> = []
  static options: any
}

export default defineComponent({
  name: 'CardDefaultList',
  components: { CardDefault },
  props: CardDefaultList.options.props,
  setup() {},
})

import gql from 'graphql-tag'

export const news = gql`
  query newsQuery(
    $after: ElementWhereUniqueInput
    $orderBy: [ElementOrderByInput!]
    $searchString: String
    $first: Int
    $codeNews: String
    $tagsSearch: PropertyRelationListRelationFilter
    $notId: String
  ) {
    elements(
      where: {
        iblock: { code: { equals: "rgs_news" } }
        id: { not: { equals: $notId } }
        propertyValues: { some: { value: { contains: $searchString } } }
        propertyValuesRelation: $tagsSearch
        code: { equals: $codeNews }
      }
      first: $first
      after: $after
      orderBy: $orderBy
    ) {
      id
      name
      activeFrom
      code
      seo {
        elementPageTitle
        elementMetaDescription
      }
      ...news_properyValues
      ...news_propertyValuesEnum
      ...news_propertyValuesRelation
    }
    elementsCount(where: { iblock: { code: { equals: "rgs_news" } } })
  }

  fragment news_properyValues on Element {
    propertyValues {
      property {
        code
      }
      value
    }
  }

  fragment news_propertyValuesRelation on Element {
    propertyValuesRelation {
      property {
        code
        type
        kind
      }
      targetFile {
        url
        size
        contentType
      }
      targetElement {
        iblock {
          code
        }
        sort
        code
        name
        id
        ...news_properyValues
        ...news_propertyValuesEnum
        ...news_relationLvl_2
      }
    }
  }

  fragment news_propertyValuesEnum on Element {
    propertyValuesEnum {
      property {
        code
      }
      value
    }
  }

  fragment news_relationLvl_2 on Element {
    propertyValuesRelation {
      property {
        code
        type
        kind
      }
      targetFile {
        url
        size
        contentType
      }
      targetElement {
        iblock {
          code
        }
        sort
        code
        name
        id
        ...news_properyValues
        ...news_propertyValuesEnum
        ...news_relationLvl_3
      }
    }
  }

  fragment news_relationLvl_3 on Element {
    propertyValuesRelation {
      property {
        code
        type
        kind
      }
      targetFile {
        url
        size
        contentType
      }
      targetElement {
        iblock {
          code
        }
        sort
        code
        name
        id
        propertyValues {
          property {
            code
          }
          value
        }
        ...news_reletionLvl_4
        ...news_propertyValuesEnum
      }
    }
  }

  fragment news_reletionLvl_4 on Element {
    propertyValuesRelation {
      property {
        code
        type
        kind
      }
      targetFile {
        url
        size
        contentType
      }
      targetElement {
        iblock {
          code
        }
        sort
        code
        name
        id
        propertyValues {
          property {
            code
          }
          value
        }
        ...news_relationLvl_5
        ...news_propertyValuesEnum
      }
    }
  }

  fragment news_relationLvl_5 on Element {
    propertyValuesRelation {
      property {
        code
        type
        kind
      }
      targetFile {
        url
        size
        contentType
      }
      targetElement {
        iblock {
          code
        }
        sort
        code
        name
        id
        propertyValues {
          property {
            code
          }
          value
        }
        ...news_propertyValuesEnum
      }
    }
  }
`

import { ref, useFetch } from '@nuxtjs/composition-api'

export const useServerFetch = (fetchCallback: () => Promise<any>): void => {
  const countTrying = ref(0)
  const fetchHandler = async () => {
    if (!countTrying.value) {
      await fetchCallback()
      countTrying.value++
    }
  }

  const { fetch } = useFetch(fetchHandler)

  fetch()
}

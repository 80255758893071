
















import {
  computed,
  defineComponent,
  SetupContext,
} from '@nuxtjs/composition-api'
import { dateAdapter } from '../../apollo/adapters/modules'
import { CardDefault } from '@/contracts/Cards'
import { defineProps } from '@/helpers/defineProps'
import Link from '@/components/Link.vue'
import { TypePages } from '@/contracts/PageTypes'

export default defineComponent({
  name: 'CardDefault',
  components: {
    Link,
  },
  props: defineProps<CardDefault>({
    title: {
      default: '',
    },
    date: {
      default: '',
    },
    url: {
      default: '',
    },
  }),
  setup(props, context: SetupContext) {
    const textLink = computed<string>(
      (): string => context.root.$store.getters['settings/btnRead']
    )
    const fullUrl = computed<string>((): string => {
      const list = context.root.$store.getters['settings/pagesList'] || []
      let url = ''
      list.forEach((item: any) => {
        if (item.uniquePages === TypePages.news) {
          url = item.fullPath.split('/').slice(0, -1).join('/')
        }
      })
      return url + props.url
    })
    const adaptedDate = () => dateAdapter(props.date, true).replace(' г.', '')
    return {
      adaptedDate,
      fullUrl,
      textLink,
    }
  },
})

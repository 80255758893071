













import { Component, Prop, mixins } from 'nuxt-property-decorator'
import { defineComponent, ref, useContext } from '@nuxtjs/composition-api'
import { useServerFetch } from '../composition/useServerFetch'
import { Logger } from '../composition/Logger'
import { SectionCore } from '@/mixins/sectionCore'
import { news as queryNews } from '@/apollo/queries/news'
import Loader from '@/plugins/loader/Load.vue'
import { QueryElementsArgs } from '@/apollo/composition-functions'
import SectionBasic from '@/components/SectionBasic.vue'
import { newsAdapter } from '@/apollo/adapters/modules'
import CardDefaultList from '@/components/CardLists/CardDefaultList.vue'

@Component
class SectionCardNews extends mixins(SectionCore) {
  @Prop() readonly news: ReadonlyArray<any> = []
  @Prop() readonly loadLastNews: boolean = false
}

export default defineComponent({
  name: 'SectionCardNews',
  components: {
    SectionBasic,
    CardDefaultList,
    Loader,
  },
  props: SectionCardNews.options.props,
  setup(props) {
    const loadNews = ref(false)
    const queryNewsAdapted = ref([])
    const ctx = useContext()
    const query = ctx.app.apolloProvider.defaultClient.query
    const filters: QueryElementsArgs = {
      orderBy: { activeFrom: 'desc' },
      first: 3,
    }

    const getLastNews = async () => {
      loadNews.value = true
      try {
        const {
          data: { elements },
        } = await query({ query: queryNews, variables: filters })
        queryNewsAdapted.value = newsAdapter(elements)
        loadNews.value = false
      } catch (e) {
        Logger.error(e)
        loadNews.value = false
      }
    }

    if (props.loadLastNews) {
      useServerFetch(getLastNews)
    }

    return {
      queryNewsAdapted,
      loadNews,
    }
  },
})
